@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/mixins/gradients";

@import "~@progress/kendo-theme-material/scss/_variables.scss"; 

@import "./variables";

$input-height:20px;
$font-size-base:11;

$fa-font-path : '../../../node_modules/font-awesome/fonts';
@import "~font-awesome/scss/font-awesome";

@import "~spinkit/spinkit.css";
@import "~@cime/ngx-slim-loading-bar/bundles/style.css";
@import "~@cime/ngx-toasty/bundles/style-bootstrap.css";
/* @import "~@progress/kendo-theme-material/scss/all.scss"; */ 
//@import "nscss/combobox/_index.scss"; 
@import "~@progress/kendo-theme-material/scss/grid/_index.scss"; 
@import "~bootstrap/scss/bootstrap";
@import "@swimlane/ngx-dnd/release/index.css";

@import "~@progress/kendo-theme-material/scss/input/_index.scss";
@import "~@progress/kendo-theme-material/scss/numerictextbox/_index.scss";
@import "~@progress/kendo-theme-material/scss/combobox/_index.scss";
@import "~@progress/kendo-theme-material/scss/multiselect/_index.scss";
@import "~@progress/kendo-theme-material/scss/datetime/_index.scss";
@import "~@progress/kendo-theme-material/scss/upload/_index.scss";
@import "~@progress/kendo-theme-material/scss/splitter/_index.scss";
@import "~@progress/kendo-theme-material/scss/grid/_index.scss";
@import "~@progress/kendo-theme-material/scss/tabstrip/_index.scss"; 

@import "~@progress/kendo-theme-material/scss/_variables.scss"; 


@import "~bootstrap/scss/bootstrap";
@import "~@swimlane/ngx-dnd/release/index.scss";

$input-height:20px;

@-webkit-keyframes sk-rotateplane {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
    } 50% {
          transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
          -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
      } 100% {
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        }
}



html, body, app-root { margin: 0; padding: 0; height: 100%; }

$theme-colors: (
    dark: #1e282c
);

$accent: #007bff;
$secondary: #ccc;
$table-cell-padding: .5rem;
$font-size-base: 0.85rem;

$input-border-radius:            0;
$input-border-radius-lg:         0;
$input-border-radius-sm:         0;

$btn-border-radius:           0;
$border-radius:               0;    
$border-radius-lg:            0;
$border-radius-sm:            0;
$dropdown-border-radius:      0;
$card-border-radius:          0;


$accent: #007bff;
$secondary: #ccc;
$table-cell-padding: .5rem;
$font-size-base: 0.85rem;

$border:#444444;

$input-border-radius:            0;
$input-border-radius-lg:         0;
$input-border-radius-sm:         0;
$btn-border-radius:           0;
$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;
$dropdown-border-radius:      0;
$card-border-radius:          0;

$control-disabled-color:white;
$control-active-color:#edecec;

$control-active-border:#666666;
$control-disabled-border:#999999;
$control-focused-color: #007bff;
$control-hover-color:#007bff;


$font-size-base: 0.85rem;
$font-size-sm: $font-size-base * .775;

$primcol:#1D5E96;
$primcol1:#006db1;
$bordercol: #777;
$inputcol: #000000;
$textcol: #333;
$ctrldisabled: #eee;


$table-header:#415266;

$calendar-font-size:12px;
$calendar-header-cell-font-size:12px;



@mixin box-border {
    /*
    border: 1px solid $secondary;

    border-top:3px solid $secondary-color;*/

    background-color: white !important;
    box-shadow: 0 0 1px rgba(0,0,0.2,.2), 0 2px 4px rgba(0,0,0,.1) !important;
    border-radius: 5px !important;
  
    font-family: "Roboto";
    font-size: 14px;
    color: #444;

    .card-header{
        background-color: white;
        padding: 5px !important;        
        border-top:3px solid #1D5E96;
  }
  .card-body{
      padding-top: 5px !important;
      padding-bottom: 5px !important;
  }
    
}

.k-autocomplete, .k-combobox, .k-dateinput, .k-datepicker, .k-datetimepicker,
.k-dropdown, .k-multiselect, .k-numerictextbox, .k-maskedtextbox, .k-timepicker,
.k-dropdowntree {
    font-size: $font-size-base;

}





table.table-sm {
    font-size: 13px;

    th {
        font-weight: normal;
    }
}

.k-grid th  {
    padding: 0.4rem 0.5rem;

}

.k-grid td{
    border-left:1px solid #cccccc;
    padding: 0.2rem 0.1rem;
    text-align: center;
}

.pointer {
    cursor: pointer;
}

body {
    padding-top: 56px;
    overflow-y: hidden;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    background-color: #edecec;
}

label {
    font-size: $font-size-sm !important;
    line-height: 1.2 !important;
}

.btn-xs, .btn-group-xs > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.675rem;
    line-height: 1.5;
    border-radius: $border-radius-sm;
}

button.close {
    outline: none;
}

/*
 * Loading animation
 */
.sk-rotating-plane {
	background-color: $primary-color !important;
	width: 80px!important;
	height: 80px!important;

    margin: 100px auto;
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
}



/*
 * Scrollbars
 */
 ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: #E6E6E6;
}

::-webkit-scrollbar-thumb {
    background: #007bff;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.card-header-tabs > .nav-item {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none;   /* Firefox all */
    -ms-user-select: none;  /* IE 10+ */
    user-select: none;  /* Likely future */
}

.card-border-top {
    @include box-border;
    
       


}


.navbar span.toggle-menu {
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    opacity: .7;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;

    &:hover {
        opacity: 1;
    }
}


input.form-control,input.form-control:focus {

}

input.ng-dirty.ng-invalid {
    border-color: #cc0000 !important;
}

textarea {
    line-height: 1.5 !important;
}

input[type="text"],
input[type="password"],
input[type="number"],
select {
    // min-height: 42px;
}


.error-tooltip .tooltip-inner {
    background-color: #e22e40;
    color: #fff;
}
.error-tooltip .arrow::before {
    border-top-color: #e22e40;
}






.ngx-datatable.bootstrap .datatable-body .progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding: 0;
  margin: 0;
  position: absolute;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear .container {
  display: block;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  height: 5px;
  -webkit-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  background-color: #aad1f9;
}
.ngx-datatable.bootstrap .datatable-body .progress-linear .container .bar {
  transition: all .2s linear;
  -webkit-animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: -webkit-transform .2s linear;
  transition: transform .2s linear;
  background-color: #106cc8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
}
/**
 * Progress bar animations
 */
 @keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
//content box
.content-box
{
background-color: white !important;
box-shadow: 0 0 1px rgba(0,0,0.2,.2), 0 2px 4px rgba(0,0,0,.1) !important;
border-radius: 5px !important;
}

// Content Header
.content-header {
  position: relative;
  padding: 0px 0px 0 0px;
  font-family: "Roboto";


  // Header Text
  > h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    font-size:30px;
    > small {
      font-size: 15px;
      display: inline-block;
      padding-left: 4px;
      font-weight: 300;
    }
  }

  > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    @include border-radius(2px);
    > li > a, > li > span {
      color: #444;
      text-decoration: none;
      display: inline-block;
      > .fa, > .glyphicon, > .icon {
        margin-right: 5px;
      }
    }
  
  }
}


.content-header>.breadcrumb{
    float: none;
    padding: 0px 0px;
    position: unset;
}


.app-pager-page-sizes.k-dropdown .k-state-focused {
    box-shadow: none !important;
}

kendo-grid {
    .k-checkbox-label {
        padding: 20px;
        margin: -9px -9px;
    }
    .k-checkbox-label::after, .k-checkbox-label::before {
        margin-top: 10px;
        margin-left: 10px;
    }
}

kendo-splitter {
    background-color: #edecec !important;
    border: 0 !important;
    .animation{

  
   
    }
    animation: slideMe .7s ease-in;
}

app-control {
    .k-multiselect-wrap .k-searchbar {
        float: initial;
    }
 
    .form-control-sm .k-multiselect-wrap {
        overflow: hidden;
    }
}

ngb-accordion .card-header {
    //padding: 0px !important;
}
 
.add-extra > kendo-grid > div > table > thead {
    display: none;
}

.windowcard{
   
    border-radius: 2px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    margin-top: 140px;
    height: 100%;
}

.windowpad{
    margin: 10px;
}

.btn-clear { 
    color: #ffffff; 
    background-color: #ffb347; 
    border-color: #FFA500; 
  } 
   
  .btn-clear:hover, 
  .btn-clear:focus, 
  .btn-clear:active, 
  .btn-clear.active, 
  .open .dropdown-toggle.btn-clear { 
    color: #ffffff; 
    background-color: #FFA500; 
    border-color: #FFA500; 
  } 
   
  .btn-clear:active, 
  .btn-clear.active, 
  .open .dropdown-toggle.btn-clear { 
    background-image: none; 
  } 
   
  .btn-clear.disabled, 
  .btn-clear[disabled], 
  fieldset[disabled] .btn-clear, 
  .btn-clear.disabled:hover, 
  .btn-clear[disabled]:hover, 
  fieldset[disabled] .btn-clear:hover, 
  .btn-clear.disabled:focus, 
  .btn-clear[disabled]:focus, 
  fieldset[disabled] .btn-clear:focus, 
  .btn-clear.disabled:active, 
  .btn-clear[disabled]:active, 
  fieldset[disabled] .btn-clear:active, 
  .btn-clear.disabled.active, 
  .btn-clear[disabled].active, 
  fieldset[disabled] .btn-clear.active { 
    background-color: #ffb347; 
    border-color: #FFA500; 
  } 
   
  .btn-clear .badge { 
    color: #ffb347; 
    background-color: #ffffff; 
  }



.underlined {
    border-bottom: 1 px solid black;
    margin-top: 10px;
    margin-bottom: 0px;
}

.btn {
    border-radius: 3px;
}

/* Custom buttons */
.custom-btn {
 
    border-radius: 3px;
    text-transform: uppercase;
}

.btn-cancel:hover
{
   color: white;
   background-color: darkred;
}

.btn-search{
    color:green;
    border-color: green;
}

.btn-search:hover{
    color: honeydew; 
}

.btn-cancel{
    color:darkred;
    border-color: darkred;
}




.accordion .card{
    @include box-border;
}

.k-grid thead {
    color: #edecec !important;
    background-color: $primary-color !important;
    font-size: .65rem;
  
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid #e9ecef;

    .k-filter-row{
        height: 20px !important;
        background-color: #dfdfdf;
        font-size: .65rem !important;
    }
    .k-filter-row:hover{
        height: 20px !important;
        background-color: #fff;
        font-size: .65rem !important;
    }
    .k-grid-filter{
        height: 20px !important;
    }
    .td{
        font-size:12px;
        background-color: white;
        border: 1px solid #aaa;
    }

    .k-filtercell .k-filtercell-wrapper > .k-textbox
    {
        border: 1px solid #cccccc;
        font-size: .65rem;
        background-color: white;
    }
}
.k-grid .k-filter-row td {
    border-right: 1px solid #ccc;
    height: 20px;
}

.k-grid-header .k-grid-filter, .k-grid-header .k-header-column-menu {
    padding: 0px;
    color: #edecec !important;
}


.k-grid tbody {
    font-size: .75rem;
}

.k-grid tbody .k-alt {
    background-color: #f6f6f6;
}

.k-grid tbody tr:hover{
    background-color:#ddd;
}

.k-pager-wrap .k-pager-number li{
    padding: 4px !important;
}

.k-pager-wrap .k-pager-numbers .k-link{
    width: 28px;
}

.k-filter-row .k-grid-filter{
    height:20px;
    background-color: #f6f9fc !important;
}


//GENERAL
.k-widget.form-control, .k-maskedtextbox.form-control .k-textbox, .k-textbox.form-control .k-numerictextbox .k-numeric-wrap{
    height:25px !important;
    font-size: 12px !important;
    font-family: Roboto, sans-serif !important; 
    .k-numeric-wrap{
        height: 25px !important;
    }
}




.k-widget.form-control .k-input {
    height:25px !important;
    font-size: 12px !important;
    font-family: Roboto, sans-serif !important; 
}


.k-widget.form-control-sm .k-input {
    height:25px !important;
    font-size: 12px !important;
    font-family: Roboto, sans-serif !important; 
}

.outlined-text-field {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #00000000;
    border: 1pt solid #757575;
    border-radius: 4px;
    color: #757575;
    margin-left: 2rem;
    padding: 1rem;
    width: calc(100% - 6rem);
  }

label {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none;   /* Firefox all */
    -ms-user-select: none;  /* IE 10+ */
     user-select: none;  /* Likely future */
     text-transform: uppercase;
     font-family: Roboto, sans-serif; 
  
  }
  
  
  .label-fonts{
    font-family: Roboto, sans-serif !important; 
    font-size: $font-size-sm !important;
    font-weight: 700;
    font-variant: small-caps !important;   

    color:#000; 
    width: 100%;
    
  }

.noborder{
    font-family: Roboto, sans-serif !important; 
    border-bottom: 1px solid $border !important;
    font-size: 12px !important;
    text-align: left;
    background-color: $control-active-color;
   
  }

  @mixin border-focus {//border-bottom: 2px solid $border;
    //box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);

    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
background-color: white;
border-bottom: 1px solid $accent;}
@mixin border-hover {border-bottom: 1px solid $accent;}



  .noborder:disabled{
      background-color: white;
  }

.form-control:focus{
    @include border-focus;
}

.form-control  .k-state-disabled{
    height:25px;
    font-size: 11px;
    background-color: $control-disabled-color;
    opacity: 1;
}

.form-control:disabled
{
    @include border-focus;
    opacity: 1;

}

.form-control{
    border:0px;
}

//
// COMOBOX
//
.k-combobox {

    
    @extend .k-text-selection !optional;

    .k-dropdown-wrap {
        border-color: $border;
     


        .k-input {
            color: $combobox-text;
        }

        .k-select {
            opacity: $icon-opacity;
        }

        // Hover
        &:hover,
        &.k-state-hover {
            @include border-hover;

            .k-select {
                opacity: 1;
            }
        }

        // Focused
        &.k-state-focused {
            @include border-focus;

            .k-select {
                opacity: $icon-focused-opacity;
            }
        }

        // Invalid state
        &.k-invalid,
        &.k-invalid:hover,
        &.k-state-invalid {
            border-color: $invalid-border;
        }
    }

    &.k-state-focused {
        > .k-dropdown-wrap {
            border-color: $combobox-focused-border;

            .k-select {
                opacity: $icon-focused-opacity;
            }
        }
    }

    // Invalid state
    &.k-state-invalid,
    &.ng-invalid.ng-touched,
    &.ng-invalid.ng-dirty {
        .k-dropdown-wrap {
            border-color: $invalid-border;
        }
    }



    .k-dropdown-wrap {
        border-width: 0 0 3;
        position: relative;
        height: 25px !important;
        background-color: #ededed;
        font-size: 11px;
        

       
      
        // Disabled
        &.k-state-disabled {
            border-bottom-style: solid;
            border-bottom-color: $control-disabled-border;
            background-color: $control-disabled-color !important;
        }
    }

    &.k-state-disabled {
        > .k-multiselect-wrap {
            border-bottom-style: solid;
            background-color: #fff;
        }
    }
}

//Combobox X icon position fix
.k-combobox .k-dropdown-wrap  {
    position: unset;
}


//POPUP
.k-popup{
    font-size: 11px;
}


//
.k-numerictextbox {
    .k-numeric-wrap {
        height: 25px !important;
        background-color: #ededed;
        border-color: $border;
        .k-icon{
            font-size: 12px;;
        }
        position: relative;

        .k-select {
            width: $control-disabled-color;
            border-width: 0;
            font-size: $control-disabled-color;
        }
// Hover
&:hover,
&.k-state-hover {
    @include border-hover;

    .k-select {
        opacity: 1;
    }
}

// Focused
&.k-state-focused {
    @include border-focus;

    .k-select {
        opacity: $icon-focused-opacity;
    }
}

        // Disabled state
        .k-state-disabled {
            border-bottom-style: solid;
            border-bottom-color: $control-disabled-border;
            background-color: $control-disabled-color !important;
        }
    }


    // Disabled state for k-state-disabled added to the k-numerictextbox
    &.k-state-disabled {
        .k-numeric-wrap {
            border-bottom-style: solid;
        }
    }
}

.k-numeric-wrap {
    height: 25px !important;
    .k-icon{
        font-size: 12px;;
    }
    position: relative;

    .k-select {
        width: $control-disabled-color;
        border-width: 0;
        font-size: $control-disabled-color;
    }


    // Disabled state
    .k-state-disabled {
        border-bottom-style: solid !important;
        border-bottom-color: $control-disabled-border;
        background-color: white;
        .k-select{
            visibility: hidden;
        }
    }
}
.k-textbox.form-control-sm {
    height: 25px !important;
}

.k-combobox .k-dropdown-wrap.k-state-disabled {
    height: 25px !important;
    .k-select
    {
        visibility: hidden;
    }
}

.k-numerictextbox .k-numeric-wrap.k-state-disabled {
    border-style: solid !important;
    background-color: white;
    border-color: $control-disabled-border;
    .k-select{
        visibility: hidden;
    }
}

.k-numerictextbox .k-numeric-wrap{
    border-style: solid !important;
    height: 25px !important;
}


.k-combobox .k-dropdown-wrap
{
    border-style: solid !important;
    height: 25px !important;
}

//NOTIFICATIONT TABS
.active-tab{
    background-color:$control-hover-color;
}


.k-dateinput,
.k-datepicker,
.k-datetimepicker,
.k-timepicker {
    @extend .k-text-selection !optional;

    // Wrappers
    .k-dateinput-wrap,
    .k-picker-wrap {
        height: 25px;
        border-bottom: 1px solid $border;
        
        background-color: #ededed;

        .k-input {
            color: $datetime-text;
        }

        .k-select {
            opacity: $icon-opacity;
        }

        .k-dateinput-wrap {
            border-bottom: 1px solid $border;
            box-shadow: none;
        }
     // Disabled
     .k-state-disabled > &,
     &.k-state-disabled {
         border-bottom-style: solid;
         background-color: white;
         border-bottom-color: #999999 !important;
     }
        // Hover
        &:hover,
        &.k-state-hover {
            @include border-hover;

            .k-select {
                opacity: $icon-hovered-opacity;
            }
        }

        // Focused
        &.k-state-focused,
        .k-state-focused > & {
            @include border-focus();

            .k-select {
                opacity: $icon-focused-opacity;
            }
        }

        .k-state-disabled {
            border-bottom-style: solid !important;
            border-bottom-color: $control-disabled-border;
            background-color: white;
            .k-select{
                visibility: hidden;
            }
        }

        // Invalid
        &.k-invalid,
        &.k-invalid:hover,
        &.k-state-invalid,
        &.ng-invalid.ng-touched,
        &.ng-invalid.ng-dirty {
            color: $error;
            border-color: $invalid-border;
        }
    }
}


.k-calendar {
    @include use-background-clip();
    border-width: 1px;
    border-color:$control-active-border;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: "Roboto";
    font-size: 12px;
    position: relative;
    overflow: hidden;
    display: inline-block;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: $rgba-transparent;
 

    .k-popup{
        border:1px solid $control-disabled-border;
        box-shadow: 1px 1px 1px rgba(145,142,142,.5);
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
        background-color: #fff;
        background-clip: initial;
    }
    .k-picker-wrap {
        border-width: 0 0 1px;
        position: relative;

        .k-select {
            border-width: 0;
            font-size: $font-size;
        }

        // Disabled
        .k-state-disabled > &,
        &.k-state-disabled {
            border-bottom-style: dashed;
        }
    } 
}


.k-multiselect.k-state-disabled > .k-multiselect-wrap {
    @include border-hover;
    font-size: 14px;
}

.form-control-sm .k-multiselect-wrap li.k-button {
    font-size: 12px;
    opacity: 1;
    background-color: $border;
    color:white;
}


.k-state-disabled,
.k-widget[disabled] {
    opacity: 1;


    .k-link,
    .k-button {
        cursor: default;
        outline: 0;
    }


    // Nested disabled
    [disabled],
    .k-state-disabled {
        opacity: 1;
        filter: grayscale(0);
    }
}

.k-calendar .k-popup {
    .k-group-header {
        text-transform: uppercase;
    }

    &.k-colorpicker-popup {
        padding: 0;
    }

    .k-outer-group-header {
        line-height: $line-height-lg;
    }

    border: 1px solid $border;
}

.k-chart-tooltip {
    background-color: #fafbfc !important; /* Nova pozadina */   
    opacity: 1 !important;            /* Prozirnost */
    border-width: 1px !important;       /* Debljina obruba */
    color: rgb(17, 16, 16) !important;  /* Boja teksta */
    padding: 3px !important;           /* Unutrašnja margina */
    font-size: 12px !important;         /* Veličina fonta */
    border-radius: 6px !important;      /* Zaobljeni uglovi */
}
.k-animation-container {


    box-shadow: 1px 1px 1px rgba(145,142,142,.5);
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    background-color: #fff;
    background-clip: initial;

    .k-button {
        box-shadow: none;
        &::before {
            display: none;
        }
    }

}

.k-list-filter {
    height: auto;

    & > .k-textbox {
        border-width: 0 0 2px;
        @include border-radius( $border-radius-lg );
    }

    & > .k-icon {
        margin-top: -.5em;
        transform: none;
    }
}

// Tooltip popup container padding
.k-animation-container-{
    box-shadow: 1px 1px 1px rgba(145,142,142,.5);
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    background-color: #fff;
    background-clip: initial;
    .k-popup {
        border: 1px solid $accent;
    }
} 

.k-calendar-header,
        .crimson .k-calendar-header .k-today {
            background: $border;
            color: white;
        }


@mixin chip {
    display: inline-block;
    padding: 0 10px 10px 10px;
    height: 25px;
    font-size: inherit;
    line-height: 25px;
    border-radius: 25px;
    widows: 100%;
    
}

.Announced { 
    @include chip;
    background-color: #ffc94b;
}

.Departed{
    @include chip;
    color: whitesmoke;
    background-color: #4A6163;
}

.Manipulations{
    @include chip;
    background-color: #7F4FC9;
    color:white;
}

.Active{
    @include chip;
    background-color: #87C735;    
}

.Canceled
{
    @include chip;
    background-color: #4A6163;  
    color:white;
    text-align: center;
}

.Completed{
    @include chip;
    background-color: #3e49bb;
    color:white;
}

.Init{
    @include chip;
    background-color: #ff9a00;
    
}

.BerthAssigned{
    @include chip;
    background-color: #003366;
    color:white;
}

.InHarbour{
    @include chip;
    background-color: #87C735;    
}

.InHarbourAndReady{
    @include chip;
    background-color: green;    
    color:white;
}

.EndManipulations{
    @include chip;
    background-color: yellow;    
   
}

.WaitingForDeparture{
    @include chip;
    background-color: coral;   
    color:white;
}

.Created {
    @include chip;
    background-color: #85C1E9;   

}

.Pending{
    @include chip;
    background-color: red;
    color:white;
}
.OnInternalParking{
    @include chip;
    background-color: purple;
    color:white;
}

.OnExternalParking{
    @include chip;
    background-color: blue;
    color:white;
}

.Blocked{
    @include chip;
    background-color: red;
    color:white;
}

.AnnounceBlocked{
    @include chip;
    background-color: red;
    color:white;
}

.LeftExternalParking{
    @include chip;
    background-color:#3e49bb;
    color:white;
}

.WaitingCoordinator{
    @include chip;
    background-color:mediumblue;
    color:white;
}

.info-box{
    background-color: white; padding-top: 10px;padding-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,.2); 
}

.info-box-new{
    background-color: white; padding-top: 10px;padding-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    margin-bottom: 10px;
}

.info-box-new-margin{
    background-color: white; padding-top: 10px;padding-bottom: 10px;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    margin-bottom: 10px;
    margin-right: 1px;
    margin-left: 1px;
    border-left:3px solid #1D5E96;
}

.info-box-operations{
    //  background-color: #1D5E96; padding-top: 10px;padding-bottom: 10px;
    background-color: #fefefe; padding-top: 10px;padding-bottom: 10px;
      color:white;
     // box-shadow: 0 2px 10px rgba(0,0,0,.2);    
      border-left: 3px solid #1D5E96;
      padding-left:20px;
  
      .heading{
          //color:white !important;
      }
  }

  .heading{
    margin-left: 1px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0.4em 0 0.6em 0;
    font-weight: normal;
    color: #006db1;
    font-family: 'Hammersmith One', sans-serif;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    position: relative;
    font-size: 14px;
    line-height: 10px;
}

.h6{
    
    font-weight: normal;
    color: #006db1;
    font-family: 'Hammersmith One', sans-serif;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    position: relative;
    font-size: 14px;
    line-height: 20px;
}


.k-splitbar{
    height:25px;
    background: red;
    .k-i-arrow-60-left, .k-i-arrow-60-right,.k-i-arrow-60-down,.k-i-arrow-60-up{
        z-index: 100000;
        position: relative;
        top:-5px;
        //border-radius: 0 10px 10px 0;
        
        //left: 20px;
        color:white;
        width: 20px;
        height: 20px;
        font-size: 30px;
    }
}